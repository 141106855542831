/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-12-17",
    versionChannel: "nightly",
    buildDate: "2024-12-17T00:06:58.837Z",
    commitHash: "c1a4df4c36531910de7bcd69236ee4124692d168",
};
